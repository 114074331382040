import React, { useContext, useEffect, useState } from "react"
import { SkoleniItem } from "./SkoleniItem"
import { Loading } from "../layout/Loading"
import { useLazyQuery } from "@apollo/client"
import { SKOLENI_LIST, SKOLENI_ONLINE, SKOLENI_ZIVE } from "../../query/skoleni"
import { PageContext } from "../../context/context"
import { EmptyList } from "../elements/EmptyList"
import { Helmet } from "react-helmet"

export const SkoleniList = ({
  cat,
  producer,
  page,
  location,
  hideHeader,
  typ,
}) => {
  const [context] = useContext(PageContext)
  const [type, setType] = useState(typ ? typ : "")
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)

  const searchString = context?.searchString
  const breadCrumb = context?.breadCrumbPath

  if (breadCrumb && breadCrumb.length > 0) {
    breadCrumb.forEach(cat => {
      title = title + " > " + cat.menuName
    })
  }

  const [getData] = useLazyQuery(
    type === "online"
      ? SKOLENI_ONLINE
      : type === "ziva"
      ? SKOLENI_ZIVE
      : SKOLENI_LIST,
    {
      variables: type && { typ: type },
      fetchPolicy: "cache-and-network",
      onCompleted: data => {
        setItems(data.skolenis)
        setLoading(false)
      },
    }
  )

  useEffect(() => {
    setItems([])
    setLoading(true)
    getData()

    //eslint-disable-next-line
  }, [type])

  //console.log(items)
  // if (searchString) {
  //   items = data?.fulltextGoods
  // }

  let title =
    type === "ziva"
      ? "Živě online"
      : type === "diplom"
      ? "Kurzy s udělením diplomu"
      : type === "balicek"
      ? "Balíčky"
      : "Diplomy a živé lekce"

  const Button = ({ text, action, value }) => {
    return (
      <button
        className={
          "px-2 py-1 rounded-md underline hover:no-underline " +
          (type === value && "text-orange-800 font-bold no-underline")
        }
        onClick={() => action(value)}
      >
        {text}
      </button>
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>

      <div className="  mx-auto py-4  max-w-6xl ">
        <div className=" ">
          <div className={" w-full text-gray-500   "}>
            <div className=" ">
              <div className=" ">
                <div className="  ">
                  <div className="max-w-5xl mx-auto px-5">
                    <div className="flex justify-between w-full items-center h-14">
                      <div className="text-orange-800 text-2xl lg:text-2xl lg:px-2 font-bold ">
                        {title}
                      </div>

                      <div>
                        {/* {!searchString && <StorageButton storage={storage} />} */}
                      </div>
                    </div>
                    <div className="text-sm space-x-1 lg:px-4">
                      <Button text={"vše"} value={""} action={setType} />
                      <Button
                        text={"jen živě online"}
                        value={"ziva"}
                        action={setType}
                      />

                      <Button
                        text={"jen diplomy"}
                        value={"diplom"}
                        action={setType}
                      />
                      <Button
                        text={"jen balíčky"}
                        value={"balicek"}
                        action={setType}
                      />
                    </div>
                  </div>
                </div>

                {loading && <Loading loading={loading} />}
                {items && items.length === 0 && !loading && (
                  <>
                    {searchString ? (
                      <EmptyList
                        text={"Nic nebylo nalezeno, zkuste změnit zadání"}
                      />
                    ) : (
                      <EmptyList text={"Žádné položky"} />
                    )}
                  </>
                )}

                <div className="mt-6 grid  gap-2 max-w-5xl mx-auto  px-5">
                  {items &&
                    items.length > 0 &&
                    items.map((item, itemIdx) => (
                      <SkoleniItem
                        key={itemIdx}
                        item={item}
                        list={items}
                        itemIdx={itemIdx}
                        hasMore={false}
                        lastElementRef={null}
                        location={location}
                        searchString={searchString}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SkoleniList
