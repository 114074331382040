import { gql } from "@apollo/client"

export const SKOLENI_LIST = gql`
  query skoleni($typ: String) {
    skolenis(typ: $typ, order: [{ id: "DESC" }]) {
      id
      nazev
      img
      alias
      cena
      delkaKurzu
      online
      prezencni
      novinka
      lektor
      termin
      typ
    }
  }
`

export const SKOLENI_ONLINE = gql`
  query skoleni($typ: String) {
    skolenis(online: 1, typ: $typ, order: [{ id: "DESC" }]) {
      id
      nazev
      img
      alias
      cena
      delkaKurzu
      online
      prezencni
      novinka
      lektor
      termin
      typ
    }
  }
`

export const SKOLENI_ZIVE = gql`
  query skoleni($typ: String) {
    skolenis(typ: $typ, order: [{ id: "DESC" }]) {
      id
      nazev
      img
      alias
      cena
      delkaKurzu
      online
      prezencni
      novinka
      lektor
      termin
      typ
    }
  }
`

export const SKOLENI_DETAIL = gql`
  query skoleni($alias: String) {
    skolenis(alias: $alias) {
      id
      nazev
      img
      alias
      typ
      cena
      delkaKurzu
      online
      prezencni
      novinka
      lektor
      termin
      terminPoznamka
      cenaPoznamka
      popis
      program
      podminky
      vyuka
      platnostLicence
      misto
      nocleh
      mistoSrazu
      typ
    }
  }
`
