import React from "react"
import { Link } from "gatsby"
import Logo from "../../images/logo.svg"
import {
  SparklesIcon,
  DevicePhoneMobileIcon,
  UsersIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid"

export const SkoleniItem = ({
  item,
  list,
  itemIdx,
  hasMore,
  lastElementRef,
}) => {
  return (
    <>
      <div
        key={item.id}
        className={
          "group  relative grid grid-cols-3  p-4 py-4 md:py-6 gap-4 bg-white hover:bg-gray-100  overflow-hidden rounded-md"
        }
      >
        <Link
          to={"/kurz/" + item.alias + "/"}
          className="space-x-2 lg:text-xl  order-1  align-middle col-span-3 md:hidden "
        >
          <h3 className="font-bold text-orange-800  inline">{item.nazev}</h3>

          {item.online === 1 && (
            <span className="bg-sky-200 rounded-md text-sky-700 font-normal   text-xs  mt-1 items-center px-2 py-0.5 inline-flex space-x-1  ">
              <DevicePhoneMobileIcon className="w-4 h-4" />
              <span>Online</span>
            </span>
          )}
          {item.prezencni === 1 && (
            <span className="bg-gray-200 rounded-md text-gray-700 font-normal   text-xs  mt-1 items-center px-2 py-0.5 inline-flex space-x-1  ">
              <UsersIcon className="w-4 h-4" />
              <span>Prezenční</span>
            </span>
          )}
          {item.novinka === 1 && (
            <span className="bg-yellow-200 rounded-md text-orange-700 font-normal   text-xs mt-1  items-center px-2 py-0.5 inline-flex space-x-1 ">
              <SparklesIcon className="w-4 h-4" />
              <span>Novinka</span>
            </span>
          )}

          <span className="bg-gray-200 rounded-md text-gray-700 font-normal   text-xs mt-1  items-center px-2 py-0.5 inline-flex space-x-1 ">
            <span className="w-0 h-4" />
            <span>
              {" "}
              {item.typ === "balicek"
                ? "Balíček"
                : item.typ === "ziva"
                ? "Živá lekce"
                : "Diplom"}
            </span>
          </span>
        </Link>
        <Link to={"/kurz/" + item.alias + "/"} className="order-3 md:order-2">
          <div className="relative w-full bg-gray-100 rounded-md overflow-hidden md:h-full">
            <div className="w-full h-full p-1 flex items-start md:items-center">
              {item.img ? (
                <img
                  src={item.img}
                  alt={item.nazev}
                  className="  mx-auto md:object-cover md:h-full rounded-md"
                />
              ) : (
                <img
                  src={Logo}
                  className="  mx-auto object-contain rounded-md opacity-50"
                />
              )}
            </div>
          </div>
        </Link>
        <div className="col-span-2 order-2 md:order-3 ">
          <div className="  ">
            <Link
              to={"/kurz/" + item.alias + "/"}
              className="space-x-2 lg:text-xl  align-middle hidden md:block"
            >
              <h3 className="font-bold text-orange-800  inline">
                {item.nazev}
              </h3>

              {item.online === 1 && (
                <span className="bg-sky-200 rounded-md text-sky-700 font-normal   text-xs  mt-1 items-center px-2 py-0.5 inline-flex space-x-1  ">
                  <DevicePhoneMobileIcon className="w-4 h-4" />
                  <span>Online</span>
                </span>
              )}
              {item.prezencni === 1 && (
                <span className="bg-gray-200 rounded-md text-gray-700 font-normal   text-xs  mt-1 items-center px-2 py-0.5 inline-flex space-x-1  ">
                  <UsersIcon className="w-4 h-4" />
                  <span>Prezenční</span>
                </span>
              )}
              {item.novinka === 1 && (
                <span className="bg-yellow-200 rounded-md text-orange-700 font-normal   text-xs mt-1  items-center px-2 py-0.5 inline-flex space-x-1 ">
                  <SparklesIcon className="w-4 h-4" />
                  <span>Novinka</span>
                </span>
              )}
              <span className="bg-gray-200 rounded-md text-gray-700 font-normal   text-xs mt-1  items-center px-2 py-0.5 inline-flex space-x-1 ">
                <span className="w-0 h-4" />
                <span>
                  {" "}
                  {item.typ === "balicek"
                    ? "Balíček"
                    : item.typ === "ziva"
                    ? "Živá lekce"
                    : "Diplom"}
                </span>
              </span>
            </Link>

            <p className=" text-sm text-gray-600 md:mt-2">
              Typ kurzu:{" "}
              <strong>
                {item.online === 1 && item.prezencni === 0 && "online"}{" "}
                {item.online === 0 && item.prezencni === 1 && "prezenční"}{" "}
                {item.online === 1 &&
                  item.prezencni === 1 &&
                  "online i prezenční"}
              </strong>
            </p>
            <p className=" text-sm text-gray-600">
              Termín kurzu:{" "}
              <strong
                dangerouslySetInnerHTML={{ __html: item.termin }}
              ></strong>
            </p>
            <p className=" text-sm text-gray-600">
              Lektor: <strong> {item.lektor}</strong>
            </p>
            <p className=" text-sm text-gray-600">
              Cena: <strong> {item.cena}</strong>
            </p>
          </div>
          <div className="flex mt-3 space-x-2 text-sm">
            <Link
              to={"/kurz/" + item.alias + "/"}
              className="bg-orange-800 px-6 py-1 rounded-md   text-white hover:bg-orange-900"
            >
              Detaily
            </Link>
            <Link
              to={"/kurz/" + item.alias + "/"}
              className="text-orange-800 px-6 py-1 rounded-md border border-orange-800 bg-white   hover:bg-gray-100"
            >
              Přihlásit
            </Link>
          </div>
        </div>

        {list.length === parseInt(itemIdx + 1) && hasMore && (
          <span ref={lastElementRef}></span>
        )}
      </div>
      <hr />
    </>
  )
}
