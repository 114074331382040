import React from "react"
import { Helmet } from "react-helmet"
import SkoleniList from "../components/goods/SkoleniList"

const BalickyPage = ({ location }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Balíčky online</title>
      </Helmet>

      <SkoleniList
        paging={16}
        page={1}
        tags={[]}
        typ={"balicek"}
        location={location}
      />
    </>
  )
}

export default BalickyPage
